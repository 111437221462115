<template>
    <div class="d-flex justify-content-center">
        <b-card class="mb-2" :disabled="isDisabled">
            <b-card-title class="d-flex justify-content-between">
                Logga in <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading"></b-icon>
            </b-card-title>
            <b-card-text>
                <form @keyup.enter="loginClicked()">
                    <label for="loginInputEmail">Användarnamn:</label>
                    <b-form-input type="text" id="loginInputEmail" v-model="email"
                        placeholder="namn@adress.se"></b-form-input>

                    <label for="loginInputPassword" class="mt-3">Lösenord:</label>
                    <b-form-input type="password" id="loginInputPassword" v-model="password"
                        placeholder="Lösenord"></b-form-input>
                        <div v-if="isUnauthorized" class="mt-2 text-danger"><small>Inloggningen misslyckades</small></div>
                </form>
            </b-card-text>
            <template #footer>
                <div class="responsive-footer">
                    <b-button variant="success" type="submit" tabindex="0" @click="loginClicked()">Logga in</b-button>
                    <b-button variant="primary" class="mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0" @click="switchToForgot()">Glömt lösenord &nbsp;&nbsp;<b-icon
                            icon="arrow-right-circle" aria-hidden="true"></b-icon></b-button>
                </div>
            </template>
        </b-card>
    </div>
</template>

<style lang="css" scoped>
@media (min-width: 768px) {
    /* For desktop: */
    .card {
        min-width: 600px;
    }
    .responsive-footer{
        display: flex;
        justify-content: space-between;
    }
}
@media (max-width: 768px) {
    /* For mobile: */
    .card {
        min-width: 300px;
    }
    .responsive-footer{
        display: grid;
    }
}
.card-body {
    background: #f1f0e6;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    data: () => ({
        isDisabled: false,
        isLoading: false,
        valid: false,
        isUnauthorized: false,

        // form values
        email: '',
        password: '',

        //rules
        emailRules: [
            v => /.+@.+/.test(v) || 'E-post är inte giltig',
        ],
    }),
    methods: {
        ...mapActions({
            // vuex module/ActionName
            login: 'authentication/login',
            getProfile: 'authentication/getProfile'
        }),

        switchToForgot() {
            this.$emit('switchToForgot')
        },
        loginClicked() {
            this.validate();
        },
        validate() {
            if (this.email && this.password) {
                this.doLogin()
            }
        },

        doLogin() {
            //set view state to loading
            this.isDisabled = true;
            this.isLoading = true;

            let user = {
                username: this.email,
                password: this.password
            }

            try {
                this.login(user).then((result) => {

                    if (result) {
                        this.$router.push("/");
                    }
                    else
                        this.isUnauthorized = true;

                    this.isDisabled = false;
                    this.isLoading = false;
                });
            } catch (error) {
                //console.log(error);
            }
        }
    }
}
</script>