<template>
    <div class="login-register">
        <div class="home">
            <div class="d-flex justify-content-center">
                <img src="../assets/demex-logo-big.png" class="responsive-img"/>
            </div>
                <Login v-if="showLogin" @switchToForgot="switchToForgot" />
            <Forgot v-else @switchToLogin="switchToLogin" />
        </div>
    </div>
</template>
<style>
.responsive-img {
  width: 100%;
  height: auto;
  max-width: 250px;
  margin-bottom: 60px;
}
    .login-register {
        margin: -30px;
        padding-top: 60px;
        height: 103vh;
        overflow-y: hidden;
        background: #003440;
    }
</style>
<script>
    import Login from '@/components/Login'
    import Forgot from '@/components/Forgot'
    export default {
        name: 'login',
        components: {
            Login,
            Forgot,
        },
        data: () => ({
            showLogin: true,
        }),
        methods: {
            switchToForgot() {
                this.showLogin = false;
            },
            switchToLogin() {
                this.showLogin = true;
            },
            
        }
    }
</script>
